import { PhoneCall } from 'lucide-react'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import NewLogo from '../../../assets/images/updated-logo.svg'
import './Banner.css'

const Banner = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='bg-banner'>
                <div id="google_translate_element"></div>
                <Container>
                    <div className='top-banner-wrap'>
                        <div className='top-banner-logo d-flex flex-column align-items-start'>
                            <div className='text-center'>
                                <div>
                                    <Image src={NewLogo} role="button" alt="logo" width={80} height={77} onClick={() => { navigate('/LandingPage') }} />
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <h3 className='banner-title'>MSR Charitable Trust</h3>
                            <h2 className='color-white f-14px'>Investing in education, health, and well-being</h2>
                            <h5 className='mt-5 color-red'>
                            <marquee>Last date to apply for schloarship was 29 th june 2024</marquee>
                            </h5>
                        </div>
                        <div className='founder-image top-banner-logo justify-content-end'>
                            
                            {/* <Image src={founderImg} role="button" alt="logo" width={80} height={77} /> */}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Banner