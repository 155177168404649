import logo from './logo.svg';
import { BrowserRouter, Outlet, useLocation, useNavigate } from 'react-router-dom';
import RoutesConfig from './RoutesConfig';

import { createBrowserHistory } from 'history'
import HeaderLP from './Components/LandingPage/HeaderLP/HeaderLP';
import Banner from './Components/LandingPage/Banner/Banner';
import callAPI from './commonservices/APIRequests';
import { persistor } from './store/store'
import { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Loader from './Shared/Loader/Loader';
import { getAddressList, getRolesList } from './commonservices/CommonAPICalls';
import { Container } from 'react-bootstrap';
import Toaster from './Shared/Toaster/Toaster';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT, SET_CLOSETOASTER, SET_SHOWLOADER, SET_WINDOWDIMENSIONS } from './store/Reducers/Shared';

function App() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();

  const { showLoader, showToaster, windowDimensions, token } = useSelector((state) => state.SharedReducer)
  const [currentLocation, setCurrentLocation] = useState('')

  const getDimension = () => {
    dispatch(SET_WINDOWDIMENSIONS(
      {
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      }
    ))
  }

  useEffect(() => {
    window.addEventListener('resize', getDimension);

    return (() => {
      window.removeEventListener('resize', getDimension);
    })
  }, [windowDimensions])

  useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname)
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!window.location.hash) document.documentElement.scrollTo(0, 0);
  }, [currentLocation])

  const logOut = () => {
    // if (token && userDetails)
    return callAPI.post('/api/users/logout')
      .then((response) => {
        if (response.status === 200) {
          dispatch(LOGOUT())
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          localStorage.clear();
          navigate('/')
        } else {
          dispatch(LOGOUT())
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          localStorage.clear();
          navigate('/')
        }
      })
  }

  const verifyToken = useCallback(() => {
    console.log(token)
    if (token && token.length > 0) {
      callAPI.post('/api/users/verify/token', null, null, 'custom')
        .then((response) => {
          if (response.status === 401) {
            // logOut()
            navigate('/')
          }
        })
    }
  }, [token])

  useEffect(() => {
    const interval = setInterval(() => {
      verifyToken();
    }, 60000 * 10);

    getRolesList();
    getAddressList();

    return () => clearInterval(interval);
  }, [verifyToken]);

  useEffect(() => {
    getRolesList()
    getAddressList()
  }, [])

  return (
    <div className="App">
      <Loader show={showLoader} styleType='fixed' />
      {
        showToaster?.show ?
          <Toaster myClassName={showToaster.class} onClose={() => { dispatch(SET_CLOSETOASTER()) }} header={showToaster.header} content={showToaster.content}></Toaster>
          : null
      }
      <Banner />
      <HeaderLP logOut={logOut} />
      <RoutesConfig />
    </div>
  );
}

export default App;
