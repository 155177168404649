import moment from 'moment';
import Holidays from 'date-holidays';
import CryptoJS from 'crypto-js';
import format from 'date-fns/format';
import { en } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { addYears, getYear, subYears } from 'date-fns';
import { AccessList } from './Models/Lists';
// import { AccessList } from './Models/Lists';
import * as XLSX from 'xlsx';

export const getUserDetails = () => {
    return localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null
}

export const currencifyInDollars = (amount = '') => {

    amount = amount ? amount.toString() : '0';

    let amt = ''
    const decimalPart = amount.indexOf('.') > -1 ? amount.slice(amount.indexOf('.'), amount.length) : '';
    amount = amount.indexOf('.') > -1 ? amount.slice(0, amount.indexOf('.')) : amount;

    while (true) {

        let amountLength = amount.length;
        if (amountLength > 3) {
            amt = amount.slice(amountLength - 3, amountLength) + ',' + amt;
            amount = amount.slice(0, amountLength - 3)
        }
        else {
            amt = amount ? amount + ',' + amt : '0';
            break;
        }

    }

    return amt ? amt.slice(0, amt.length - 1) + decimalPart : "0"

}

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const replaceAllExceptDigits = (value) => {

    return value.replace(/[^0-9.]/g, '');

}

export const convertToBaseDate = (value) => {
    return moment(new Date(value)).utcOffset("+05:30").format('DD-MM-YYYY')
}

export const convertToBaseDate1 = (value) => {
    return value ? format(new Date(value), 'dd-LLLL-yyyy') : null;
}
export const convertToBaseDate2 = (value) => {
    return value ? format(new Date(value), 'dd-LLL-yyyy') : null;
}

export const convertToBaseFormatDate = (value, isFromDatabase = false, showTime = true, monthFormat = 'MMMM') => {

    if (!value)
        return '';

    if (isFromDatabase) {

        value = new Date(value);

        if (showTime)
            return moment.utc(value).format(monthFormat + ' D, YYYY hh:mm A');
        else
            return moment.utc(value).format(monthFormat + ' D, YYYY');
    }
    else
        return moment.utc(value).format(monthFormat + ' D, YYYY');

}

export const convertToBaseFormatMobile = (value) => {

    if (value) {

        if (value.toString().length === 10) {

            const arr = value.toString().match(/^(\d{3})(\d{3})(\d{4})$/);

            if (arr)
                return '(' + arr[1] + ') ' + arr[2] + '-' + arr[3];
            else
                return "";

        }
        else
            return value;

    }
    else
        return '';

}

export const viewDocument = (fileType, url) => {

    if (url.indexOf('base64') > -1) {

        var binaryString = atob(url.substring(url.indexOf('base64,') + 7));
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: fileType });
        const constructedURL = window.URL.createObjectURL(blob);
        const newTab = window.open(constructedURL);

        if (fileType && fileType.toLowerCase().indexOf('image') === -1 && fileType.toLowerCase().indexOf('pdf') === -1 && fileType.toLowerCase() !== 'text/plain') {

            setTimeout(() => {
                newTab.close();
            }, 2000)

        }

    }
    else {

        const newTab = window.open(url);

        if (fileType && fileType.toLowerCase().indexOf('image') === -1 && fileType.toLowerCase().indexOf('pdf') === -1 && fileType.toLowerCase() !== 'text/plain') {

            setTimeout(() => {
                newTab.close();
            }, 2000)

        }

    }

}

export const add5DaysExcludingHolidays = (date) => {

    if (date) {

        const holidays = new Holidays('US');
        let count = 0;

        do {

            date.setDate(date.getDate() + 1);

            if (!holidays.isHoliday(date) && (date.getDay() !== 0 && date.getDay() !== 6))
                count++;

        } while (count < 5)

        return moment.utc(date).format('MMMM D, YYYY');

    }
    else
        return '';

}

export const addCustomExcludingHolidays = (date, noOfDays) => {

    if (date) {

        const holidays = new Holidays('US');
        let count = 0;

        do {

            date.setDate(date.getDate() + 1);

            if (!holidays.isHoliday(date) && (date.getDay() !== 0 && date.getDay() !== 6))
                count++;

        } while (count < noOfDays)

        return moment.utc(date).format('MMMM D, YYYY');

    }
    else
        return '';

}

export const downloadFile = async (url, fileName) => {

    await fetch(url)
        .then(r => r.blob())
        .then(blob => {

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

        });

}

export const encrypt = (value) => {

    if (value)
        return CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENCRYPTION_KEY).toString();
    else
        return '';

}

export const decrypt = (value) => {

    if (value) {
        const bytes = CryptoJS.AES.decrypt(value, process.env.REACT_APP_ENCRYPTION_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    else
        return '';

}

export const convertCurrencyToShortNotation = (value) => {

    if (value) {

        const valueLength = value.toString().substring(0, value.toString().indexOf('.')).length;

        if (valueLength >= 10)
            value = (+value / 1000000000).toFixed(2) + 'B';
        else if (valueLength >= 7)
            value = (+value / 1000000).toFixed(2) + 'M';
        else if (valueLength >= 4)
            value = (+value / 1000).toFixed(2) + 'K';

        return value;

    }
    else
        return '';

}

export const maskAccountNumber = (value) => {

    if (value) {

        const valueLength = value.toString().length;

        if (valueLength > 6)
            value = value.substring(0, 2) + '*'.repeat(valueLength - 6) + value.substring(valueLength - 4);

        return value;

    }
    else
        return '';

}

export const toggleToaster = (status, message, setToaster) => {

    if (status === 'success') {
        setToaster({
            header: 'Success',
            class: 'success-toaster',
            content: message,
            showToaster: true
        });
    }

    if (status === 'error') {
        setToaster({
            header: 'Error',
            class: 'error-toaster',
            content: message,
            showToaster: true
        });
    }

}

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const colorsList = (index) => {

    const colorsList = ['#ffd78f', '#82ca9c', '#91cecb', '#f49ac1', '#9aa9f4']
    while (index > colorsList.length)
        index = index - colorsList.length;

    return colorsList[index]

}

export const stepperCount = (index) => {

    let stepperLength = [];

    while (stepperLength.length < index) {
        stepperLength.push({ label: '' })
    }

    return stepperLength

}

export const getNumberOfFloors = (data) => {
    let temp = data?.split('+')
    let floorNo = (temp[1] ? Number(temp[1]) : 0) + 1
    let floorsList = []
    for (let i = 0; i < floorNo; i++) {
        floorsList.push(i === 0 ? 'G' : `G+${i}`)
    }

    return floorsList

}

export const getPhaseList = (data) => {
    let tempData = []
    data.map((item) => {
        tempData.push({
            value: item?.name,
            label: item?.name
        })
    })
    return tempData
}

export const GanttChartDatesPopulate = async (data, newDate) => {
    let projectStartDate = data.reduce((min_date, task) => new Date(task.start_date).getTime() < new Date(min_date).getTime() ? task.start_date : min_date, data[0].start_date)
    let newDateTime = new Date(newDate).getTime()
    const timeDiff = newDateTime - new Date(projectStartDate).getTime()
    let tempData = data.map(item => {
        return {
            ...item,
            // text: <Link to={`../Home/TaskDetails/${item?._id}`} className="text-decoration-none text-dark">{item?.text}</Link>,
            text: item?.text,
            start_date: new Date(new Date(item?.start_date).getTime() + timeDiff),
            end_date: new Date(new Date(item?.end_date).getTime() + timeDiff)
        }
    })
    let projectEndDate = tempData.reduce((max_date, task) => new Date(task.end_date).getTime() > new Date(max_date).getTime() ? task.end_date : max_date, tempData[0].end_date)
    return {
        tasks: tempData,
        projectDates: {
            projectStartDate: newDate,
            projectEndDate
        }
    }
}

export const debugBase64 = (base64URL) => {
    var win = window.open();
    win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

export const buildRolesList = async (data) => {
    const SrProjectEngineer = (data.filter(obj => obj?.role === "Sr.Project Engineer"))
    const ProjectEngineer = (data.filter(obj => obj?.role === "Project Engineer"))
    const ProjectManager = (data.filter(obj => obj?.role === "Project Manager"))
    const PMC = (data.filter(obj => obj?.role === "Project Management In Construction"))
    const ElectricalEngineer = (data.filter(obj => obj?.role === "Electrical Engineer"))
    const DesignEngineer = (data.filter(obj => obj?.role === "Design Engineer"))
    const Director = (data.filter(obj => obj?.role === "Director"))
    return {
        SrProjectEngineer,
        ProjectEngineer,
        ProjectManager,
        PMC,
        ElectricalEngineer,
        DesignEngineer,
        Director,
        allUsers: data
    }
}

export const isPreviousDate = (date) => {
    return new Date().getTime() > new Date(date).getTime()
}
export const isFutureDate = (date) => {
    console.log(date)
    return new Date().getTime() < new Date(date).getTime()
}

export const addDateDays = (date, days) => {
    const dateTime = new Date(date).getTime()
    const daysTime = days * (1000 * 3600 * 24)
    return new Date(dateTime + daysTime)
}

// edit:['Director','Project Management In Construction','Project Manager','Sr.Project Engineer','Sales','Design Engineer','Project Engineer','Accounts','Supply Team','Customer'],
export const showUserAccess = (component, role) => {
    role = role ? role : 'guest'
    const tempData = AccessList.filter(item => item?.Component?.toUpperCase() === component?.toUpperCase())[0]
    if (tempData) {
        const permission = tempData?.Edit?.indexOf(role) !== -1 ? 'Edit' : tempData?.View?.indexOf(role) !== -1 ? 'View' : 'Restricted'
        return permission
    } else {
        return 'Restricted'
    }

}

export const checkEmptySpaces = (formData) => {
    return Object.values(formData).filter(item => typeof item === 'string' && item?.length).every(item => item?.trim()?.length)
}

export const checkEmptySS = (value) => {
    return value?.length && value?.trim()?.length === 0
}

export const generateYearsList = (noOfYears) => {
    const currentYear = getYear(new Date())
    const pastYears = []
    // const futureYears = []
    for (let index = 0; index < noOfYears; index++) {
        const tempDate = getYear(subYears(new Date(), index + 1))
        pastYears.unshift({ label: tempDate, value: tempDate })
    }
    // for (let index = 0; index < noOfYears; index++) {
    //     const tempDate = getYear(addYears(new Date(), index + 1))
    //     futureYears.push({label: tempDate, value: tempDate})        
    // }

    return [...pastYears, { label: currentYear, value: currentYear }].reverse()

}

export const generateAddressList = (data) => {
    const tempList = []
    if (data) {
        Object.keys(data).map(item => tempList.push({
            label: item,
            value: item
        }))
    }
    return tempList
}
export const generateVillageList = (data) => {
    const tempList = []
    if (data) {
        data.map(item => tempList.push({
            label: item,
            value: item
        }))
    }
    return tempList
}

export const youTubeURLtoEmbedURL = (url) => {
    const str = url;
    const res = str.split("=");
    const embeddedUrl = "https://www.youtube.com/embed/" + res[1];
    return embeddedUrl
}

export const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}

export const checkIndianNumber = (mobileNumber) => {
    var expr = /^(0|91)?[6-9][0-9]{9}$/;
    // console.log(expr.test(mobileNumber))

    // return !expr.test(mobileNumber)
    if (mobileNumber) {
        return mobileNumber.toString()?.length >= 10
    } else {
        return false
    }
}

export const checkNullUndefined = (value) => {
    return value === null || value === undefined
}

export const arrangeEventsInAscending = (data) => {

    if (data) {
        return [...data].sort((a, b) => (new Date(b.startDate)).getTime() - (new Date(a.startDate)).getTime())
    } else {
        return null
    }
}

export const arrangeEventsInDescending = (data) => {

    if (data) {
        return [...data].sort((a, b) => (new Date(a.startDate)).getTime() - (new Date(b.startDate)).getTime())
    } else {
        return null
    }
}

export const arrangeApplicationsInDescending = (data) => {

    if (data) {
        return [...data].sort((a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime())
    } else {
        return null
    }
}

export const twoWayBind = (key, value, setState) => {
    if (typeof value === 'object') {
        setState(prevState => {
            return {
                ...prevState,
                [key]: value?.value
            }
        })
    } else {
        setState(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }
}

export const generateExcel = (data, fileName) => {

    //Sample Data
    // const data = [
    //     ['Name', 'Age', 'Gender'],
    //     ['John', 30, 'Male'],
    //     ['Jane', 25, 'Female'],
    //     ['Bob', 40, 'Male'],
    // ];

    if (data?.length) {
        const workbook = XLSX.utils.book_new();

        // Add a worksheet to the workbook
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        return XLSX.writeFile(workbook, fileName?.length ? `${fileName}.xlsb` : "Excel.xlsb");
    }
}

export const generatePatientsDataForExcel = (data) => {
        
    const tempExcelData = []

    if(data.length){
    const tempData = [...data]

    const excelHeader = ['Patient Id', 
    'Patient Name', 
    'Phone No', 
    'Aadhaar No', 
    'Address', 
    'Gender', 
    'Age', 
    'Health Issues', 
    'Medicine', 
    'Health Condition']

    tempExcelData.push(excelHeader)

    tempData.map(item => tempExcelData.push(
        [
            item.patientId, 
            item.patientName,
            String(item.phoneNumber),
            item.aadharNumber,
            item.address?.addressLine + ',' + item.address?.village + ',' + item.address?.mandal + ',' + item.address?.district + ',' + item.address?.state,
            item.gender,
            String(item.age),
            item.healthIssues,
            item.medicine,
            item.healthCondition
        ]))
    }
    return tempExcelData
}

