import { createSlice } from "@reduxjs/toolkit";

export const RolesReducer = createSlice({
    name: 'Roles',
    initialState: {
        rolesList: []
    },
    reducers: {
        SET_ROLES_LIST: (state, action) => {
            state = {
                ...state,
                rolesList: action.payload
            }
            return state;
        },
    }
})

export const { SET_ROLES_LIST } = RolesReducer.actions;

export default RolesReducer.reducer