import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/store';
import { Provider } from 'react-redux';
import Loader from './Shared/Loader/Loader';
import { interceptor } from './commonservices/APIRequests';
import RoutesConfig from './RoutesConfig';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory()

const root = ReactDOM.createRoot(document.getElementById('root'));
interceptor()
root.render(
  // <React.StrictMode>
    <Suspense fallback={<Loader show={true} styleType='fixed' />}>
      <Provider store={store}>
        <PersistGate loading={<Loader show={true} styleType='fixed' />} persistor={persistor}>
        <BrowserRouter history={history}>
          <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
