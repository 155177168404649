import React from 'react';
import { Image } from 'react-bootstrap';
import { Slash } from 'lucide-react';

import { history } from '../index';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  handleNavigate() {
    history.push('/');
    document.location.reload()
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return !this.props?.showContent ? (
        <>
          <div>
            {/* <Image className="logo-header" role="button" onClick={() => { this.handleNavigate() }} src={amplify_infra_logo} /> */}
          </div>
          <div className="error-boundry">
            <Slash size={40} />
            <h4 style={{ textAlign: 'center', color: '#FF8500' }}>Failed to load Data.</h4>
          </div>
        </>
      )
        :
        null;
    }
    return this.props?.children;
  }

}

export default ErrorBoundary;