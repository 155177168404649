import React, { Suspense, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import callAPI from '../../commonservices/APIRequests';
import ErrorBoundary from '../ErrorBoundary';
import Loader from '../Loader/Loader';
// import { useAuth } from '../AuthProvider';

const ProtectedRoute = ({ children, onLogout }) => {

    const location = useLocation()
  
    useEffect(() => {
      const userDetails = localStorage.getItem('userDetails')
        ? JSON.parse(localStorage.getItem('userDetails'))
        : null;
      const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  
      if (token?.length > 0 && userDetails?.roleId?._id) {
        callAPI.post('/api/users/verify/token', null, null, 'custom')
          .then((response) => {
            if (response.status === 401 || response.status === 400) {
              onLogout();
            }
          })
          .catch(() => {
            onLogout();
          });
      } else {
        onLogout();
      }
    }, [onLogout]);
  
    return (
      <Suspense fallback={<Loader show={true} styleType='fixed' />}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Suspense>
    );
  };
  
  export default ProtectedRoute;