import { PhoneCall } from 'lucide-react'
import React, { useState } from 'react'
import { Container, Nav, NavLink, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { getUserDetails, showUserAccess } from '../../../commonservices/CommonMethods'
import '../LandingPage.css'
import '../../../Shared/css/colors.css'


const HeaderLP = (props) => {

    const navigate = useNavigate()
    const { userDetails } = useSelector((state) => state.SharedReducer)

    const [navbarRoutes, setNavbarRoutes] = useState([
        ['/signup', 'CREATE USER'],
        ['/blogs', 'BLOGS'],
        ['/scheduling', 'SCHEDULING'],
        ['/userManagement', 'USER MANAGEMENT'],
        ['/gallery','GALLERY'],
        ['/uploadFiles', 'UPLOAD FILES']      

    ])



    const handleLogin = () => {
        if (userDetails?.roleId?._id) {
            props.logOut()
        } else {
            navigate('/Login')
        }
    }

    return (
        <div>
            <Navbar className='nav-bar-styles' bg="light" collapseOnSelect expand="lg">
                <div className='container-fluid'>
                    {userDetails?.roleId?._id ?
                        <OverlayTrigger placement={'bottom'} overlay={<Tooltip>{userDetails?.firstName}</Tooltip>}>
                            <Navbar.Brand className='d-flex align-items-center f-16 me-0'>
                                Welcome,&nbsp;<span className='fileName-span f-16'>{userDetails?.firstName}&nbsp;</span> (<span className='color-00a74f'>{userDetails?.roleId?.roleName}</span>)
                            </Navbar.Brand>
                        </OverlayTrigger>
                        : <>
                            <Navbar.Brand style={{ minWidth: '175px' }} className='invisible'>
                            </Navbar.Brand>
                        </>
                    }
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="d-flex justify-content-between" style={{ width: '70vw' }}>
                            <div className='d-flex align-items-center nav-link-div'>
                                <Nav.Link href="#services" eventKey={1} className={`${(window.location.hash)?.includes('#services') ? 'active' : ''} fixed-width `} onClick={() => { navigate('/LandingPage#services') }}><span className='f-12'>SERVICES</span></Nav.Link>
                                <Nav.Link href='#aboutUs' eventKey={2} className={`${(window.location.hash)?.includes('#aboutUs') ? 'active' : ''} fixed-width `} onClick={() => { navigate('/LandingPage#aboutUs') }}><span className='f-12'>ABOUT US</span></Nav.Link>
                                {navbarRoutes.filter(nav => showUserAccess(nav[1], userDetails?.roleId?.roleName) !== 'Restricted').map((item, index) => <Nav.Link   key={index} className={`${(window.location.pathname)?.includes(item[0]) ? 'active' : ''} menu-width `} eventKey={index+3} onClick={() => { navigate(item[0]) }}><span className='f-12'>{item[1]}</span></Nav.Link>)}
                            </div>
                            <div>
                                <Nav.Link eventKey={7} className="button-styles bg-color-87D29D active login-nav-btn fixed-width-login" role="button" onClick={() => { handleLogin() }}>
                                    {userDetails?.roleId?._id ? 'Log Out' : 'Log In'}
                                </Nav.Link>
                            </div> 
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}

export default HeaderLP