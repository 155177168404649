import { createSlice } from "@reduxjs/toolkit";

export const EventsReducer = createSlice({
    name: 'Events',
    initialState: {
        medicalEvents: [],
        foodEvents: []
    },
    reducers: {
        ADD_MEDICAL_EVENT: (state, action) => {
            state = {
                ...state,
                medicalEvents: [
                    ...state.medicalEvents,
                    action.payload
                ]
            }
            return state;
        },
        ADD_FOOD_EVENT: (state, action) => {
            state = {
                ...state,
                foodEvents: [
                    ...state.foodEvents,
                    action.payload
                ]
            }
            return state;
        },
    }
})

export const { ADD_MEDICAL_EVENT, ADD_FOOD_EVENT } = EventsReducer.actions;

export default EventsReducer.reducer