import axios from 'axios';
import { history } from '../index';
import { getUserDetails } from './CommonMethods';
import { store } from '../store/store'
import { SET_SHOWLOADER, SET_SHOWTOASTER, SET_TOKEN } from '../store/Reducers/Shared';

const apiURL = window.location.origin.indexOf('localhost') > -1 ?
    'http://localhost:5000'
    // 'http://test.amplifyinfra.com/node/'
    : `${window.location.origin}/node`;

const axiosApiInstance = axios.create({ baseURL: apiURL });

const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null

export const interceptor = () => {

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
        async config => {
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
            const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null
            if (token && userDetails?.roleId._id) {

                config.headers = {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }                
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });

    // Response interceptor for API calls
    axiosApiInstance.interceptors.response.use((response) => {
        store.dispatch(SET_SHOWLOADER(false))
        if (response.status === 200 && response.data.token) {
            localStorage.setItem('token', response.data.token);
        }
        return response
    }, async function (error) {
        console.log(error)
        store.dispatch(SET_SHOWLOADER(false))
        store.dispatch(SET_SHOWTOASTER({type:'error', response: error?.response?.data}))
        if (error.response && error.response.status === 401) {
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
            if (token) {
                store.dispatch(SET_TOKEN(null))
                localStorage.removeItem('token');
            }
            localStorage.setItem('sessionTimeOut', true);
        }

        return Promise.reject(error);
    });
}

const callAPI = {
    get: (url, data, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))
        interceptor()
        return axiosApiInstance.get(url, { params: data }, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });


    },
    post: (url, data, fileData, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))
        if(!fileData){
            data = { ...data, roleId: data?.roleId ? data.roleId : getUserDetails()?.roleId?._id}
        }else{
            data.append('roleId',getUserDetails()?.roleId?._id ?? null)
        }
        return axiosApiInstance.post(url, data, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });

    },

    del: (url, data, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))
        data = { ...data, roleId: data?.roleId ? data.roleId : getUserDetails()?.roleId?._id}
        return axiosApiInstance.delete(url, {data : data}, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });

    },

    delWithParams: (url, data, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))

        return axiosApiInstance.delete(url, { params: data }, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });

    },

    patch: (url, data, fileData, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))
        if(!fileData){
            data = { ...data, roleId: data?.roleId ? data.roleId : getUserDetails()?.roleId?._id}
        }else{
            data.append('roleId',getUserDetails()?.roleId?._id ?? null)
        }
        return axiosApiInstance.patch(url, data, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });

    },

    put: (url, data, hideToaster) => {
        store.dispatch(SET_SHOWLOADER(true))
        data = { ...data, roleId: data?.roleId ? data.roleId : getUserDetails()?.roleId?._id}
        return axiosApiInstance.put(url, data, hideToaster)
            .then(response => {
                if(!hideToaster){
                    store.dispatch(SET_SHOWTOASTER({type:'success', response}))
                }
                return response
            })
            .catch(error => { return callAPI.catchError(error) });

    },


    catchError: (response) => {
        const res = {
            message: '',
            status: response.response ? response.response.status : null
        }
        if (response.response && response.response.data.message && response.response.data.message.length > 0) {
            res.message = response.response.data.message;
        }
        else {
            res.message = 'Something went wrong.';
        }
        return res;
    }
}

export default callAPI;