// import { Loader2 } from 'lucide';
import './Loader.css'
import LoaderGif from '../../assets/images/loader10.gif'

const Loader = (props) => {

    const classes = 'app-loader ' + props?.styleType

    const styles = props?.style
    
    return props?.show ? (<div className={classes} style={styles}>
                            <img src={LoaderGif} alt='...Loading'/>
                            {/* <p>Loading...</p> */}
                         </div>) : null

}

export default Loader