import React, { useState } from 'react';
import { Row, Col, Toast } from 'react-bootstrap';

import './Toaster.css'

const Toaster = (props) => {
  
  const [showToaster, setShowToaster] = useState(true);

  const toggleClose = () => {
    setShowToaster(!showToaster);
    props?.onClose()
  }

  return (
    <Row>
      <Col xs={6}>
        <Toast style=
          {{
            position: 'fixed',
            top: 10,
            right: 40,
            zIndex: 999,
          }}
          show={showToaster} onClose={toggleClose} delay={3000} autohide className={` ${props?.myClassName ? props?.myClassName : ''} toasterShow`}>
          <Toast.Header>
            <span className="mr-auto">{props?.header}</span>
          </Toast.Header>
          <Toast.Body>{props?.content}</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}



export default Toaster;