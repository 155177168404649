export const AccessList = [
    //User Dashboard
    {
        Component: 'User Management',
        Edit: ['Admin'],
        View: [],
        Restricted: ['Manager', 'Field_Officer', 'guest'],
    },
    {
        Component: 'Create User',
        Edit: ['Admin'],
        View: [],
        Restricted: ['Manager', 'Field_Officer', 'guest'],
    },
    {
        Component: 'Blogs',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: ['guest'],
        Restricted: [],
    },
    {
        Component: 'Scheduling',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: [],
        Restricted: ['guest'],
    },
    {
        Component: 'UPLOAD FILES',
        Edit: ['Admin'],
        View: ['Admin'],
        Restricted: ['guest','Manager', 'Field_Officer'],
    },
    {
        Component: 'GALLERY',
        Edit: [],
        View: ['guest','Admin','Manager', 'Field_Officer'],
        Restricted: [],
    },
    

    {
        Component: 'Application Status',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: ['guest'],
        Restricted: [],
    },
    {
        Component: 'Kanban',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: [],
        Restricted: ['guest'],
    },
    {
        Component: 'Health Camp',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: ['guest'],
        Restricted: [],
    },
    {
        Component: 'Food Camp',
        Edit: ['Admin', 'Manager', 'Field_Officer'],
        View: ['guest'],
        Restricted: [],
    },   
]

export const PersonTitle = [

    {
        value: "Mr",
        label: "Mr"
    },
    {
        value: "Ms",
        label: "Ms"
    },
    {
        value: "Mrs",
        label: "Mrs"
    },

]
export const Categorylist = [

    {
        value: "scholarship",
        label: "scholarship"
    },
    {
        value: "food",
        label: "food"
    },
    {
        value: "medical",
        label: "medical"
    },

]


export const roles = [

    {
        value: "Admin",
        label: "Admin"
    },
    {
        value: "Manager",
        label: "Manager"
    },

    {
        value: "Field Officer",
        label: "Field Officer"
    }

]

export const DoctorsDesignations = [
    {
        value: "MD",
        label: "MD"
    },

    {
        value: "MB BS",
        label: "MB BS"
    },
    {
        value: "DO",
        label: "DO"
    },

    {
        value: "MD CM",
        label: "MD CM"
    },

    {
        value: "MB BCh BAO",
        label: "MB BCh BAO"
    },

    {
        value: "DPM",
        label: "DPM"
    },
]

export const StaffDesignation = [

    {
        value: "Nurse",
        label: "Nurse"
    },

    {
        value: "Helper",
        label: "Helper"
    },
]







export const villages = [

    {
        value: "Abbapur (B)",
        label: "Abbapur (B)"
    },

    {
        value: "Abbapur (M)",
        label: "Abbapur (M)"
    },

    {
        value: "Abhangapatnam",
        label: "Abhangapatnam"
    },

    {
        value: "Alzapur",
        label: "Alzapur"
    },

    {
        value: "Ananthagiri",
        label: "Ananthagiri"
    },

    {
        value: "Ankanpalle",
        label: "Ankanpalle"
    },

    {
        value: "Binola",
        label: "Binola"
    },

    {
        value: "Dharmaram",
        label: "Dharmaram"
    },

    {
        value: "Dharyapur",
        label: "Dharyapur"
    },

    {
        value: "Fathenagar",
        label: "Fathenagar"
    },

    {
        value: "Jannipalle",
        label: "Jannipalle"
    },

    {
        value: "Kamalapur",
        label: "Kamalapur"
    },

    {
        value: "Kaddepalle",
        label: "Kaddepalle"
    },

    {
        value: "Khadirabad",
        label: "Khadirabad"
    },

    {
        value: "Kosli",
        label: "Kosli"
    },

    {
        value: "Lingapur",
        label: "Lingapur"
    },

    {
        value: "Maddepalle",
        label: "Maddepalle"
    },

    {
        value: "Mahandham",
        label: "Mahandham"
    },

    {
        value: "Mittapur",
        label: "Mittapur"
    },

    {
        value: "Mokanpalle",
        label: "Mokanpalle"
    },

    {
        value: "Nagepur",
        label: "Nagepur"
    },

    {
        value: "Naleshwar",
        label: "Naleshwar"
    },

    {
        value: "Nandigaon",
        label: "Nandigaon"
    },

    {
        value: "Narayanpur",
        label: "Narayanpur"
    },

    {
        value: "Navipet",
        label: "Navipet"
    },

    {
        value: "Nizampur",
        label: "Nizampur"
    },

    {
        value: "Pothangal",
        label: "Pothangal"
    },

    {
        value: "Rampur",
        label: "Rampur"
    },

    {
        value: "Shaikhapur",
        label: "Shaikhapur"
    },

    {
        value: "Shiranpalle",
        label: "Shiranpalle"
    },

    {
        value: "Tungini",
        label: "Tungini"
    },

    {
        value: "Yamcha",
        label: "Yamcha"
    },

]
export const Degrees = [

    {
        value: "engineering",
        label: "Engineering"
    },

    {
        value: "medicine",
        label: "Medicine"
    },

    {
        value: "pharmacy",
        label: "Pharmacy"
    },

    {
        value: "degree",
        label: "Degree"
    },

]
export const ChequeStatus = [
    {
        value: true,
        label: "Cheque received"
    },
    {
        value: false,
        label: "Not yet received"
    }
]

export const Inter = [
    {
        value: "intermediate 1st-Year",
        label: "intermediate 1st-Year"
    },
    {
        value: "intermediate 2nd-Year",
        label: "intermediate 2nd-Year"
    },

]