import { React } from 'react'
import callAPI from "./APIRequests"
import { store } from '../store/store'
import { SETUSERDETAILS } from "../store/Reducers/Shared"
import { buildRolesList } from "./CommonMethods"
import { Navigate, useNavigate } from "react-router"
import { persistor } from '../store/store'
import { SET_ROLES_LIST } from '../store/Reducers/Roles'
import { SET_ADDRESS_LIST } from '../store/Reducers/Villages'

export const getRolesList = () => {
    return callAPI.get('/api/roles/all', null, 'custom')
    .then((response) => {
        if (response.status === 200) {
            if(response.data.data){
                const rolesList = []
                response.data.data?.map((item) => {
                    rolesList.push({
                        label: item?.roleName,
                        value: item?.roleName,
                        _id: item?._id
                    })
                })
                store.dispatch(SET_ROLES_LIST(rolesList))
                }                
            }
        })
}

export const getAddressList = () => {
    return callAPI.get('api/address/all', null, 'custom')
    .then((response) => {
        if (response.status === 200) {
            if(response.data.data){
                store.dispatch(SET_ADDRESS_LIST(response.data.data))
                }                
            }
        })
}

