import { createSlice } from "@reduxjs/toolkit";

const userDetails = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')) : null

export const SharedReducer = createSlice({
    name: 'Shared',
    initialState:
        localStorage.getItem('token') && userDetails?.roleId?._id ?

            {
                isUserAuthenticated: true,
                isLoaded: true,
                token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
                userDetails: userDetails,
                roleId: userDetails?.roleId?._id,
                currentTab: '',
                showLoader: false,
                showToaster: {
                    header: '',
                    class: '',
                    content: '',
                    show: false
                },
                windowDimensions: {
                    dynamicWidth: window.innerWidth,
                    dynamicHeight: window.innerHeight
                }
            } :
            {
                isUserAuthenticated: false,
                isLoaded: true,
                token: null,
                userDetails: null,
                currentTab: '',
                showLoader: false,
                showToaster: {
                    header: '',
                    class: '',
                    content: '',
                    show: false
                },
                windowDimensions: {
                    dynamicWidth: window.innerWidth,
                    dynamicHeight: window.innerHeight
                }
            }
    ,

    reducers: {
        LOGIN: (state, action) => {
            localStorage.setItem('userDetails', JSON.stringify(action.payload))
            localStorage.setItem('token', action.payload?.sessionToken)
            state = {
                ...state,
                isUserAuthenticated: true,
                userDetails: action.payload,
                roleId: action.payload.roleId ? action.payload.roleId._id : null,
                token: action.payload?.sessionToken
            }
            return state;
        },
        LOGOUT: (state) => {
            localStorage.removeItem('userDetails')
            localStorage.removeItem('token')
            state = {
                ...state,
                isUserAuthenticated: false,
                userDetails: null,
                roleId: null,
                token: null
            }
            return state;
        },
        SET_TOKEN: (state, action) => {
            localStorage.setItem('token', action.payload)
            state = {
                ...state,
                token: action.payload
            }
            return state;
        },
        CHANGETAB: (state, action) => {
            state = {
                ...state,
                currentTab: action.payload
            }
            return state;
        },
        SETUSERDETAILS: (state, action) => {
            localStorage.setItem('userDetails', JSON.stringify(action.payload))
            state = {
                ...state,
                userDetails: action.payload,
                roleId: action.payload.roleId ? action.payload.roleId._id : null
            }
            return state;
        },
        SET_SHOWLOADER: (state, action) => {
            state = {
                ...state,
                showLoader: action.payload
            }
            return state;
        },
        SET_SHOWTOASTER: (state, action) => {
            state = {
                ...state,
                showToaster: action.payload?.type === 'success' ?
                    {
                        header: 'Success',
                        class: 'success-toaster',
                        content: action.payload?.response?.data?.message + (action.payload.message ? action.payload.message : ''),
                        show: true
                    }
                    :
                    {
                        header: 'Error',
                        class: 'error-toaster',
                        content: action.payload?.response?.message + (action.payload.message ? action.payload.message : ''),
                        show: true
                    }
            }
            return state;
        },
        SET_CLOSETOASTER: (state, action) => {
            state = {
                ...state,
                showToaster: {
                    header: '',
                    class: '',
                    content: '',
                    show: false
                }
            }
            return state;
        },
        SET_WINDOWDIMENSIONS: (state, action) => {
            state = {
                ...state,
                windowDimensions: action.payload
            }                        
            return state;
        }
    }
})

export const { LOGIN, LOGOUT, SET_TOKEN, CHANGETAB, SETUSERDETAILS, SET_SHOWLOADER, SET_SHOWTOASTER, SET_CLOSETOASTER, SET_WINDOWDIMENSIONS } = SharedReducer.actions;

export default SharedReducer.reducer