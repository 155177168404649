import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { persistReducer, persistStore } from 'redux-persist';

import SharedReducer from './Reducers/Shared'
import EventsReducer from './Reducers/Events';
import RolesReducer from './Reducers/Roles';
import VillagesReducer from './Reducers/Villages';

const rootPersistConfig = {
    key: 'root',
    storage,
  }
const userPersistConfig = {
    key: 'root',
    storage: sessionStorage,
  }

  const rootReducer = combineReducers({
    SharedReducer, 
    EventsReducer,
    RolesReducer,
    VillagesReducer
  })

const persistedReducer = persistReducer(userPersistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    // middleware: [thunk]
})

export const persistor = persistStore(store)