import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import ProtectedRoute from './Shared/ProtectedRoute/ProtectedRoute';
import { LOGOUT } from './store/Reducers/Shared';
import { persistor, store } from './store/store';
import { useLocation } from 'react-router-dom';

const ApplicationStatus = React.lazy(() => import('./Components/ApplicationStatus/ApplicationStatus'));
const LandingPage = React.lazy(() => import('./Components/LandingPage/LandingPage'));
const Login = React.lazy(() => import('./Components/LoginUser/Login/Login'));
const Signup = React.lazy(() => import('./Components/LoginUser/Register/Signup'));
const HealthCamp = React.lazy(() => import('./Components/Camps/HealthCamp/HealthCamp'));
const FoodCamp = React.lazy(() => import('./Components/Camps/FoodCamp/FoodCamp'));
const Blogs = React.lazy(() => import('./Components/Blogs/Blogs'));
const ViewBlogDetails = React.lazy(() => import('./Components/Blogs/BlogsComponent/ViewBlogDetails/ViewBlogDetails'));
const BlogsComponent = React.lazy(() => import('./Components/Blogs/BlogsComponent/BlogsComponent'));
const ResetPassword = React.lazy(() => import('./Components/LoginUser/ResetPassword/ResetPassword'));
const EventScheduling = React.lazy(() => import('./Components/MasterData/EventScheduling/EventScheduling'));
const MasterData = React.lazy(() => import('./Components/MasterData/MasterData'));
const UserManagement = React.lazy(() => import('./Components/UserManagement/UserManagement'));
const Gallery = React.lazy(()=>import ("./Components/UploadIMages/Gallery"))
const UploadImages = React.lazy(()=>import("./Components/UploadIMages/UploadImages"))

const RoutesConfig = () => {
  const location = useLocation();
  const { isUserAuthenticated, userDetails } = useSelector((state => state.SharedReducer))

  const navigate = useNavigate()

  const handleLogout = useCallback(() => {
    if (isUserAuthenticated) {
      store.dispatch(LOGOUT());
      persistor.pause();
      persistor.flush().then(() => {
        return persistor.purge();
      });
      localStorage.clear();
      navigate('/');
    }
  }, [navigate]);

  // useEffect(() => {
  //   if (!userDetails?.roleId?._id) {
  //     store.dispatch(LOGOUT());
  //     persistor.flush().then(() => {
  //       return persistor.purge();
  //     });
  //     localStorage.clear();
  //     navigate('/');
  //   }
  // }, [userDetails])
  useEffect(() => {
    if (!isUserAuthenticated) {
        const url = location.pathname
        const dataStr = url.split('/resetPassword/')[1];
        dataStr && navigate(`/resetPassword/${dataStr}`);
        persistor.pause();
        persistor.flush().then(() => {
            return persistor.purge();
        });
        localStorage.clear();
    }
    else if (!userDetails?.roleId?._id) {
        store.dispatch(LOGOUT());
        navigate('/');
        persistor.pause();
        persistor.flush().then(() => {
            return persistor.purge();
        });
        localStorage.clear();
    }
   
}, [userDetails])

  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/LandingPage`} />} />
      <Route path="/LandingPage" element={<ProtectedRoute onLogout={handleLogout}><LandingPage /></ProtectedRoute>} />
      <Route path="/login" element={<ProtectedRoute onLogout={handleLogout}><Login /></ProtectedRoute>} />
      <Route path="/eventScheduling" element={<ProtectedRoute onLogout={handleLogout}><EventScheduling /></ProtectedRoute>} />
      <Route path="/scheduling" element={<ProtectedRoute onLogout={handleLogout}><MasterData /></ProtectedRoute>} />
      <Route path="/applicationStatus" element={<ProtectedRoute onLogout={handleLogout}><ApplicationStatus /></ProtectedRoute>} />
      <Route path="/signup" element={<ProtectedRoute onLogout={handleLogout}><Signup /></ProtectedRoute>} />
      {/* <Route path="/blogs" element={<ProtectedRoute onLogout={handleLogout}><Blogs /></ProtectedRoute>} /> */}
      <Route path="/blogs" element={<ProtectedRoute onLogout={handleLogout}><BlogsComponent /></ProtectedRoute>} />
      {/* <Route path="/ViewBlog/:blogId" element={<ProtectedRoute onLogout={handleLogout}><ViewBlog /></ProtectedRoute>} /> */}
      <Route path="/ViewBlog/:blogId" element={<ProtectedRoute onLogout={handleLogout}><ViewBlogDetails /></ProtectedRoute>} />
      <Route path="/healthCamp" element={<ProtectedRoute onLogout={handleLogout}><HealthCamp /></ProtectedRoute>} />
      <Route path="/foodCamp" element={<ProtectedRoute onLogout={handleLogout}><FoodCamp /></ProtectedRoute>} />
      <Route path="/resetPassword/:dataStr" element={<ProtectedRoute onLogout={handleLogout}><ResetPassword /></ProtectedRoute>} />
      <Route path="/userManagement" element={<ProtectedRoute onLogout={handleLogout}><UserManagement /></ProtectedRoute>} />
      <Route path="/gallery" element={<ProtectedRoute onLogout={handleLogout}><Gallery /></ProtectedRoute>} />
      <Route path="/uploadFiles" element={<ProtectedRoute  onLogout={handleLogout}><UploadImages/></ProtectedRoute> }/>
      <Route path='*' exact={true} element={<Navigate to={`/LandingPage`} />} />
    </Routes>
  )
}

export default RoutesConfig