import { createSlice } from "@reduxjs/toolkit";

export const VillagesReducer = createSlice({
    name: 'Villages',
    initialState: {
        allAddress: {},
        statesList: [],
        districtsList: [],
        villagesList: []
        
    },
    reducers: {
        SET_ADDRESS_LIST: (state, action) => {
            state = {
                ...state,
                allAddress: action.payload
            }
            return state;
        },
    }
})

export const { SET_ADDRESS_LIST } = VillagesReducer.actions;

export default VillagesReducer.reducer